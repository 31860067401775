/**
 * Internal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SmsStatus } from './smsStatus';
import { SmsProvider } from './smsProvider';


export interface SmsNotificationDto { 
    inquiryIdentifier?: string | null;
    externalMsgId?: string | null;
    from?: string | null;
    to?: string | null;
    text?: string | null;
    status?: SmsStatus;
    sentVia?: SmsProvider;
    providerErrorCode?: string | null;
}

