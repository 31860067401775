import { Component } from '@angular/core';
import { AnalyticsService } from '../core/services/analytics.service';
import { InquiryAnalytics } from '../core/models/inquiry-analytics';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-debug',
  templateUrl: './debug.component.html',
  styleUrls: ['./debug.component.scss'],
  providers: [AnalyticsService],
})
export class DebugComponent {
  protected currentTenantIdentifier: string;
  protected currentInquiryId: string;

  protected result: InquiryAnalytics;
  constructor(
    private readonly analyticsService: AnalyticsService,
    private readonly snackBar: MatSnackBar,
  ) {}

  getInquiryData() {
    if (this.currentTenantIdentifier && this.currentInquiryId) {
      this.analyticsService
        .getAnalyticsForTenantAndInquiry(
          this.currentTenantIdentifier,
          this.currentInquiryId,
        )
        .subscribe({
          next: (x) => {
            this.result = x;
          },
          error: () => {
            this.result = null;
            this.snackBar.open('Error while loading inquiry data.', 'Ok', {
              duration: 2_500,
            });
          },
        });
    }
  }
}
