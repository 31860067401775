import { CreateTenantStandalone } from '../../../../api/gen';

export class CreateTenantStandaloneRequest {
  identifier?: string | null;
  name?: string | null;
  connectionString?: string | null;

  static toRequest(req: CreateTenantStandaloneRequest): CreateTenantStandalone {
    return {
      identifier: req.identifier,
      name: req.name,
      connectionString: req.connectionString,
    } as CreateTenantStandalone;
  }
}
