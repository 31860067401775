import { Injectable } from '@angular/core';
import { TenantService as Api } from '../../../api/gen';
import { map, Observable } from 'rxjs';
import { Tenant } from '../models/tenant';
import { CreateTenantRequest } from '../models/request/create-tenant-request';
import { CreateTenantStandaloneRequest } from '../models/request/create-tenant-standalone-request';
import { CreateTenantAdminUserRequest } from '../models/request/create-tenant-admin-user-request';
import { Employee } from '../models/employee';

@Injectable({ providedIn: 'root' })
export class TenantService {
  constructor(private readonly tenantService: Api) {}

  createNewTenant(request: CreateTenantRequest): Observable<Tenant> {
    return this.tenantService
      .internalTenantTenantIdentifierPost(
        request.identifier,
        CreateTenantRequest.toRequest(request),
      )
      .pipe(map((dto) => Tenant.fromDto(dto)));
  }

  createNewStandaloneTenant(
    request: CreateTenantStandaloneRequest,
  ): Observable<Tenant> {
    return this.tenantService
      .internalTenantTenantIdentifierStandalonePost(
        request.identifier,
        CreateTenantStandaloneRequest.toRequest(request),
      )
      .pipe(map((dto) => Tenant.fromDto(dto)));
  }

  createAdminUserForTenant(
    tenantIdentifier: string,
    request: CreateTenantAdminUserRequest,
  ): Observable<Employee> {
    return this.tenantService
      .internalTenantTenantIdentifierAdminInitPost(
        tenantIdentifier,
        CreateTenantAdminUserRequest.toRequest(request),
      )
      .pipe(map((dto) => Employee.fromDto(dto)));
  }

  getTenantById(tenantId: string): Observable<Tenant> {
    return this.tenantService
      .internalTenantIdTenantIdGet(tenantId)
      .pipe(map((dto) => Tenant.fromDto(dto)));
  }

  getTenantByIdentifier(tenantIdentifier: string): Observable<Tenant> {
    return this.tenantService
      .internalTenantTenantIdentifierGet(tenantIdentifier)
      .pipe(map((dto) => Tenant.fromDto(dto)));
  }

  getDefaultTenant(): Observable<Tenant> {
    return this.tenantService
      .internalTenantDefaultGet()
      .pipe(map((dto) => Tenant.fromDto(dto)));
  }

  getTenants(): Observable<Tenant[]> {
    return this.tenantService.internalTenantGet().pipe(
      map((x) => {
        return x.map((dto) => Tenant.fromDto(dto));
      }),
    );
  }

  deleteTenant(
    tenantId: string,
    tenantIdentifier: string,
  ): Observable<unknown> {
    return this.tenantService.internalTenantTenantIdentifierIdTenantIdGdprDeleteDelete(
      tenantId,
      tenantIdentifier,
    );
  }
}
