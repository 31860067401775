import { AnalyticsService as Api } from '../../../api/gen';
import { InquiryAnalytics } from '../models/inquiry-analytics';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable()
export class AnalyticsService {
  constructor(private readonly analyticsService: Api) {}

  getAnalyticsForTenantAndInquiry(
    tenantIdentifier: string,
    inquiryIdentifier: string,
  ): Observable<InquiryAnalytics> {
    return this.analyticsService
      .internalAnalyticsTenantIdentifierAnalyzeInquiryIdGet(
        tenantIdentifier,
        inquiryIdentifier,
      )
      .pipe(map((dto) => InquiryAnalytics.fromDto(dto)));
  }
}
