/**
 * Internal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { WebSocketUpdateDto } from './webSocketUpdateDto';
import { UsedCameraDto } from './usedCameraDto';
import { AppointmentSummaryDto } from './appointmentSummaryDto';
import { PermissionUpdateDto } from './permissionUpdateDto';
import { SmsNotificationDto } from './smsNotificationDto';
import { InquiryDto } from './inquiryDto';
import { AvailableDevicesDto } from './availableDevicesDto';
import { ClientInformationDto } from './clientInformationDto';
import { NetworkInformationDto } from './networkInformationDto';
import { VideoAppointmentDto } from './videoAppointmentDto';


export interface InquiryAnalyticsDto { 
    inquiry?: InquiryDto;
    smsNotification?: SmsNotificationDto;
    roomDetail?: VideoAppointmentDto;
    appointmentSummary?: AppointmentSummaryDto;
    clientInformation?: Array<ClientInformationDto> | null;
    usedCamera?: Array<UsedCameraDto> | null;
    availableDevices?: Array<AvailableDevicesDto> | null;
    permissionUpdates?: Array<PermissionUpdateDto> | null;
    webSocketUpdateDtos?: Array<WebSocketUpdateDto> | null;
    networkInformationDtos?: Array<NetworkInformationDto> | null;
}

