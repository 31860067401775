<app-heading-container
  title="Debugging"
  subTitle="Hier können Sie Informationen über eine bestimmte Anfrage abfragen"
>
</app-heading-container>

<div class="input-row">
  <mat-form-field appearance="fill">
    <mat-label>Tenant Identifier</mat-label>
    <input matInput type="text" [(ngModel)]="currentTenantIdentifier" />
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>Inquiry Id</mat-label>
    <input matInput type="text" [(ngModel)]="currentInquiryId" />
  </mat-form-field>
  <button mat-flat-button color="primary" (click)="getInquiryData()">
    Inquiry Daten laden
  </button>
</div>

<div class="result">
  <pre>{{ result | json }}</pre>
</div>
