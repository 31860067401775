import {
  AppointmentSummaryDto,
  AvailableDevicesDto,
  ClientInformationDto,
  InquiryAnalyticsDto,
  InquiryDto,
  NetworkInformationDto,
  PermissionUpdateDto,
  SmsNotificationDto,
  UsedCameraDto,
  VideoAppointmentDto,
  WebSocketUpdateDto,
} from '../../../api/gen';

export class InquiryAnalytics {
  inquiry?: InquiryDto;
  smsNotification?: SmsNotificationDto;
  roomDetail?: VideoAppointmentDto;
  appointmentSummary?: AppointmentSummaryDto;
  clientInformation?: ClientInformationDto[] | null;
  usedCamera?: UsedCameraDto[] | null;
  availableDevices?: AvailableDevicesDto[] | null;
  permissionUpdates?: PermissionUpdateDto[] | null;
  webSocketUpdateDtos?: WebSocketUpdateDto[] | null;
  networkInformationDtos?: NetworkInformationDto[] | null;

  static fromDto(dto: InquiryAnalyticsDto): InquiryAnalytics {
    return {
      inquiry: dto.inquiry,
      smsNotification: dto.smsNotification,
      roomDetail: dto.roomDetail,
      appointmentSummary: dto.appointmentSummary,
      clientInformation: dto.clientInformation,
      usedCamera: dto.usedCamera,
      availableDevices: dto.availableDevices,
      permissionUpdates: dto.permissionUpdates,
      webSocketUpdateDtos: dto.webSocketUpdateDtos,
      networkInformationDtos: dto.networkInformationDtos,
    } as InquiryAnalyticsDto;
  }
}
