/**
 * Internal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type Department = 'None' | 'Sales' | 'Technology' | 'Procurement' | 'Marketing' | 'Support' | 'Management';

export const Department = {
    None: 'None' as Department,
    Sales: 'Sales' as Department,
    Technology: 'Technology' as Department,
    Procurement: 'Procurement' as Department,
    Marketing: 'Marketing' as Department,
    Support: 'Support' as Department,
    Management: 'Management' as Department
};

