/**
 * Internal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CorporateIdentityConfigurationDto } from './corporateIdentityConfigurationDto';


export interface FeatureConfigurationDto { 
    id?: string;
    numberOfUsersIncluded?: number;
    numberOfCallsPerMonth?: number | null;
    concurrentUsers?: number;
    deleteDataAfterDays?: number | null;
    ocrAllowed?: boolean;
    recordingAllowed?: boolean;
    livePhotosAllowed?: boolean;
    liveChatTranslationsEnabled?: boolean;
    customersCanUploadFilesDuringCall?: boolean;
    corporateIdentityConfiguration?: CorporateIdentityConfigurationDto;
}

