/**
 * Internal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type DevicePermission = 'Denied' | 'Granted' | 'Prompt' | 'PermissionApiUnavailable' | 'Unknown' | 'DeniedOnSystemLevel';

export const DevicePermission = {
    Denied: 'Denied' as DevicePermission,
    Granted: 'Granted' as DevicePermission,
    Prompt: 'Prompt' as DevicePermission,
    PermissionApiUnavailable: 'PermissionApiUnavailable' as DevicePermission,
    Unknown: 'Unknown' as DevicePermission,
    DeniedOnSystemLevel: 'DeniedOnSystemLevel' as DevicePermission
};

