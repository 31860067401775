import { CreateTenant } from '../../../../api/gen';

export class CreateTenantRequest {
  identifier?: string | null;
  name?: string | null;

  static toRequest(req: CreateTenantRequest): CreateTenant {
    return {
      identifier: req.identifier,
      name: req.name,
    } as CreateTenant;
  }
}
