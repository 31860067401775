import { Component } from '@angular/core';
import { AnalyticsService } from '../core/services/analytics.service';
import { InquiryAnalytics } from '../core/models/inquiry-analytics';

@Component({
  selector: 'app-debug',
  templateUrl: './debug.component.html',
  styleUrls: ['./debug.component.scss'],
  providers: [AnalyticsService],
})
export class DebugComponent {
  protected currentTenantIdentifier: string;
  protected currentInquiryId: string;

  protected result: InquiryAnalytics;
  constructor(private readonly analyticsService: AnalyticsService) {}

  getInquiryData() {
    if (this.currentTenantIdentifier && this.currentInquiryId) {
      this.analyticsService
        .getAnalyticsForTenantAndInquiry(
          this.currentTenantIdentifier,
          this.currentInquiryId,
        )
        .subscribe((x) => {
          this.result = x;
        });
    }
  }
}
