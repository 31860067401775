/**
 * Internal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Origin } from './origin';
import { Author } from './author';
import { AssociatedCallPeriod } from './associatedCallPeriod';


export interface AttachmentDto { 
    containerName?: string | null;
    inquiryIdentifier?: string;
    attachmentIdentifier?: string | null;
    fileName?: string | null;
    note?: string | null;
    extension?: string | null;
    contentType?: string | null;
    blobUrl?: string | null;
    blobPath?: string | null;
    thumbnailUrl?: string | null;
    thumbnailPath?: string | null;
    annotationBlobUrl?: string | null;
    annotationThumbnailUrl?: string | null;
    createdAt?: string;
    period?: AssociatedCallPeriod;
    origin?: Origin;
    author?: Author;
    shared?: boolean;
    isInProcess?: boolean;
    processPercent?: number | null;
    tenantId?: string | null;
}

