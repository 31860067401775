import { Component, signal, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TenantService } from '../../../core/services/tenant.service';
import { Tenant } from '../../../core/models/tenant';
import { TenantBrandingColorComponent } from '../tenant-branding-color/tenant-branding-color.component';

@Component({
  selector: 'app-realm-details-overview',
  templateUrl: './realm-details-overview.component.html',
  styleUrls: ['./realm-details-overview.component.scss'],
  providers: [TenantService],
})
export class RealmDetailsOverviewComponent {
  tenantId: string;
  protected tenant = signal<Tenant>(null);

  @ViewChild(TenantBrandingColorComponent)
  brandingColorComponent: TenantBrandingColorComponent;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly tenantService: TenantService,
  ) {
    this.tenantId = this.route.snapshot.queryParamMap.get('tenantId');
    this.tenantService.getTenantById(this.tenantId).subscribe((tenant) => {
      this.tenant = signal(tenant);
    });
  }

  onFeatureConfigurationChanged() {
    this.brandingColorComponent.reload();
  }
}
