import { CreateTenantAdminUser } from '../../../../api/gen';

export class CreateTenantAdminUserRequest {
  tenantIdentifier?: string | null;
  adminUsername?: string | null;
  adminPassword?: string | null;
  adminEmail?: string | null;
  adminFirstName?: string | null;
  adminLastName?: string | null;

  static toRequest(x: CreateTenantAdminUserRequest): CreateTenantAdminUser {
    return {
      tenantIdentifier: x.tenantIdentifier,
      adminUsername: x.adminUsername,
      adminPassword: x.adminPassword,
      adminEmail: x.adminEmail,
      adminFirstName: x.adminFirstName,
      adminLastName: x.adminLastName,
    } as CreateTenantAdminUser;
  }
}
